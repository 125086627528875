export enum LotStatus {
  NOT_RELEASED = "Not Released",
  AVAILABLE = "Available",
  EOI = "EOI",
  CONTRACT_EXECUTED = "Contract Executed",
  UNCONDITIONAL = "Unconditional",
  SETTLED = "Settled",
  OPTION = "Option",
  OFFLINE = "Offline",
  HOLD = "Hold",
  PRE_CONTRACT = "Pre-Contract",
  SOLD = "Sold",
  UNDER_CONTRACT = "Under Contract"
}
